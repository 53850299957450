import dashboard from './dashboard'
import bbook from './bbook'

export default [
  {
    icon: ['far', 'ufo'],
    title: 'Dashboard ',
    route: 'dashboard-ecommerce',
  },
  // {
  //   icon: ['far', 'chart-bar'],
  //   title: 'VaR Analysis',
  //   route: 'var-analysis',
  // },

  // ...bbook,

  {
    icon: ['far', 'chart-pie'],
    title: 'Market Exposure',
    route: 'market-exposure'
  },
  {
    icon: ['far', 'users'],
    title: 'User Statistics',
    route: 'user-stats'
  },
  {
    icon: ['far', 'filter'],
    title: 'Company P/L',
    route: 'company-p-l'
  },
  {
    icon: ['far', 'cubes'],
    title: 'Deep Dive',
    route: 'deep-dive'
  },
  // {
  //   icon: ['far', 'bolt'] ,
  //   title: 'Stress Test',
  //   route: 'stress-test',
  // },
  // {
  //   icon: ['fas', 'shield-alt'],
  //   title: 'Credit Risk',
  //   route: 'credit-risk',
  // },
  {
    icon: ['far', 'file-chart-line'],
    title: 'Reports',
    route: 'reports',
  },
  // {
  //   icon: ['far', 'siren-on'],
  //   title: 'Alerts',
  //   route: 'alerts',
  // },
  {
    icon: ['far', 'cog'],
    title: 'Settings',
    route: 'settings-group-management',
  },
]