<template>
  <div class="navbar-container d-flex content align-items-center py-auto" style="height: 40px">

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex ml-5">
      <div class="ml-5" v-if="$store.state.app.dataLiveTime">Last updated: {{$store.state.app.dataLiveTime}}</div>
      <div class="ml-5" v-else>Last updated: {{now}}</div>
      <!-- <bookmarks $store.state.app.shallShowOverlay /> -->
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <notification-dropdown /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <locale />
      <!-- <search-bar />
      <cart-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
// import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
// import DarkToggler from './components/DarkToggler.vue'
// import SearchBar from './components/SearchBar.vue'
// import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import moment from 'moment'


export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    Locale,
    // DarkToggler,
    // SearchBar,
    // CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data(){
   return {
      now: '',
      current: moment().format('HH:mm:ss - DD/MM/YYYY')
   }
  },

  mounted(){
    setInterval(() => {
      this.now = moment().format('HH:mm:ss - DD/MM/YYYY')
    }, 1000);
  }

}
</script>
