<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
            />
          </span>
          <h2 class="brand-text mb-0">
            {{ appName.toUpperCase() }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  components: {
    BLink,
    BImg,
  },
  computed: {
    ...mapGetters({
      faviconURL: 'companySettings/companyFaviconURL'
    }),
  },
  watch: {
    faviconURL(newFaviconURL) {
      document.getElementById('favicon').href = newFaviconURL 
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    
    const fetchFavicon = async () => {
      await store.dispatch('companySettings/fetchCompanyFavicon')
    }
    fetchFavicon()

    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style scoped>
.brand-logo >>> img {
  width: 30px !important;
  height: 30px !important;
}
</style>
